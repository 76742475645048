import React from 'react';
import img from '../images/web.svg'; 
import img2 from '../images/app.svg';
import img3 from '../images/hosting.svg';
import img4 from '../images/consultation.svg';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            
            <section data-aos="zoom-in-down">

                    <div className="my-4 py-4">
                        
                        <h2 className="my-2 text-center text-3xl text-green-800  font-bold">Services</h2>
                        
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-green-800'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-gray-600">
                            Let us help build your next application.</h2>
                    </div>



                














                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-10">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2  text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Web Applications</h2>
                                    <p className="text-lg font-medium">
                                    Partner with us, a trusted web app development company, to meet your business's needs. We build modern apps, automate processes, and implement real-time solutions.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2  text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Mobile Applications</h2>
                                    <p className="text-lg font-medium">
                                    We specialize in creating high-quality mobile applications. Whether you're a startup or an established business, our expertise and passion will help turn your vision into reality.
                                    </p>
                                </div>
                            </div>

                       
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2  text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Consultations</h2>
                                    <p className="text-lg font-medium">
                                    With 20+ years of experience in software analysis and design, we guide teams in building, enhancing, or taking over legacy projects.
                                    </p>
                                </div>
                            </div>                    
                        </div>
                    </div>
            </section>
        </div>
    )
}

export default Services;