import React from "react";

const Clients = () => {
    return (
        
            <div id="team" className="py-12" >

                <section data-aos="zoom-in-down">


                    <div className="my-4 py-4">

                        <h2 className="my-2 text-center text-3xl text-green-800 uppercase font-bold">team</h2>

                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-green-800'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-gray-600">We are deeply committed to the growth and success of our clients.</h2>
                    

                        <div className="mx-auto max-w-screen-2xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">


                            <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-2 dark:text-gray-800">




                            <div className="flex flex-col justify-between bg-white p-6 shadow-sm sm:p-8 dark:bg-gray-50">
                                    <div className="max-w-md p-8 sm:flex sm:space-x-6 dark:text-gray-800">
                                        <div className="flex-shrink-0 w-full mb-6 h-44 sm:h-32 sm:w-32 sm:mb-0">
                                            <img
                                                src="https://source.unsplash.com/100x100/?portrait?1"
                                                alt=""
                                                className="object-cover object-center w-full h-full rounded dark:bg-gray-500"
                                            />
                                        </div>
                                        <div className="flex flex-col space-y-4">
                                            <div>
                                                <h2 className="text-2xl font-semibold">MD Reaj Chowdhury</h2>
                                                <span className="text-sm dark:text-gray-600">
                                                    Director, Mobile Solutions
                                                </span>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>





                                <div className="flex flex-col justify-between bg-white p-6 shadow-sm sm:p-8 dark:bg-gray-50">
                                    <div className="max-w-md p-8 sm:flex sm:space-x-6 dark:text-gray-800">
                                        <div className="flex-shrink-0 w-full mb-6 h-44 sm:h-32 sm:w-32 sm:mb-0">
                                            <img
                                                src="https://source.unsplash.com/100x100/?portrait?1"
                                                alt=""
                                                className="object-cover object-center w-full h-full rounded dark:bg-gray-500"
                                            />
                                        </div>
                                        <div className="flex flex-col space-y-4">
                                            <div>
                                                <h2 className="text-2xl font-semibold">MD Reaj Chowdhury</h2>
                                                <span className="text-sm dark:text-gray-600">
                                                    Director, Mobile Solutions
                                                </span>
                                            </div>
                                        
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                        </div>

                    </div>

                </section>

            </div>

        
    );
};

export default Clients;
