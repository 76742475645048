import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render(
  <GoogleReCaptchaProvider
  reCaptchaKey="6LcUMY0qAAAAAMT_7jRMJ_lZMCDe3y672TzkFfDl">
      <React.StrictMode>
        <App />
      </React.StrictMode>
  </GoogleReCaptchaProvider>
  ,
  
  document.getElementById('root')
);
